import React, { useEffect, useMemo, useState } from "react";

const ResponsiveContext = React.createContext({
  sm: false,
  md: false,
  isMobile: true,
  isDesktop: false,
});

interface ProviderProps {
  children: React.ReactChild;
}

const BREAKPOINTS = {
  sm: 640,
  md: 768,
};

export const ResponsiveProvider = ({ children }: ProviderProps) => {
  const [sm, setSm] = useState(false);
  const [md, setMd] = useState(false);
  const flags = useMemo(
    () => ({ sm, md, isMobile: !sm, isDesktop: sm }),
    [sm, md]
  );

  useEffect(() => {
    const handleResize = () => {
      setSm(window.innerWidth >= BREAKPOINTS.sm);
      setMd(window.innerWidth >= BREAKPOINTS.md);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={flags}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContext;
