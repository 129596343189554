import { ALL_WORDS, COMMON_WORDS } from "./wordlist";
import uniq from "lodash/uniq";

const SPLIT_WORDS = COMMON_WORDS.concat(ALL_WORDS).map((word) =>
  word.split("")
);

export const solve = (entriesIn: [string, string | null][]) => {
  const entries = entriesIn.map(([resultString, answerString]) => [
    resultString.split(""),
    answerString === null ? null : answerString.split(""),
  ]) as [string[], string[] | null][];
  const finalAnswer = entries[entries.length - 1][1]!;
  // cumulatively keep track of wrong guesses and assume those aren't reused in future guesses
  const pastWrongs = new Set();

  return entries.map(([result, answer], entryIndex) => {
    if (!result) {
      return [];
    }

    if (answer) {
      answer
        .filter((_, i) => result[i] === " ")
        .forEach((l) => pastWrongs.add(l));
      return [answer.join("")];
    }

    const knownLettersInNextGuess = finalAnswer.filter(
      (_, i) => entries[entryIndex + 1][0][i] === "G"
    );
    const numMatchedLettersInNextGuess = entries[entryIndex + 1][0].filter(
      (l) => l !== " "
    ).length;
    const matchedLettersInNextGuess = entries[entryIndex + 1][1]
      ? entries[entryIndex + 1][1]!.filter(
          (_, i) => entries[entryIndex + 1][0][i] !== " "
        )
      : null;

    const futureGuessedLetters = new Set();
    const futureWrongLetters = new Set();
    const unguessedLetters = new Set();

    for (let i = entryIndex + 1; i < entries.length; i++) {
      const [futureResult, futureAnswer] = entries[i];

      if (futureResult.every((result) => result !== "Y")) {
        finalAnswer.forEach((letter, index) => {
          if (
            futureResult[index] === " " &&
            finalAnswer.filter((l) => l === letter).length === 1
          ) {
            unguessedLetters.add(letter);
          }
        });
      }

      if (futureAnswer) {
        futureAnswer
          .filter((_, j) => futureResult[j] === " ")
          .forEach((l) => futureWrongLetters.add(l));
        futureAnswer
          .filter((_, j) => futureResult[j] !== " ")
          .forEach((l) => futureGuessedLetters.add(l));
      }
    }

    return SPLIT_WORDS.filter((letters) => {
      // assume no double letters on first word
      if (entryIndex === 0 && uniq(letters).length < 5) {
        return false;
      }

      // assume known wrong letters aren't retried
      if (letters.some((letter) => pastWrongs.has(letter))) {
        return false;
      }

      // these letters we know they haven't guessed yet from future guesses
      if (letters.some((letter) => unguessedLetters.has(letter))) {
        return false;
      }

      const matchedLetters = letters.filter((_, i) => result[i] !== " ");
      const correctLetters = letters.filter((_, i) => result[i] === "G");

      // can't have number of matched letters exceed final answer
      if (
        uniq(matchedLetters).some(
          (letter) =>
            matchedLetters.filter((l) => l === letter).length >
            finalAnswer.filter((l) => l === letter).length
        )
      ) {
        return false;
      }

      if (numMatchedLettersInNextGuess === matchedLetters.length) {
        // must account for known letters in next guess
        if (knownLettersInNextGuess.some((l) => !matchedLetters.includes(l))) {
          return false;
        }

        // current matches must equal next matches
        if (
          matchedLettersInNextGuess &&
          matchedLettersInNextGuess.sort().join("") !==
            matchedLetters.sort().join("")
        ) {
          return false;
        }
      }

      const wrongLetters = letters.filter((_, i) => result[i] === " ");

      if (wrongLetters.some((letter) => futureWrongLetters.has(letter))) {
        return false;
      }

      const cumulatedCorrectGuessesInWord = new Set();
      const cumulatedPartialGuessesInWord = new Set();

      return letters.every((letter, index) => {
        if (result[index] === "G") {
          if (letter === finalAnswer[index]) {
            cumulatedCorrectGuessesInWord.add(letter);
            return true;
          } else {
            return false;
          }
        }

        if (result[index] === "Y") {
          if (
            letter !== finalAnswer[index] &&
            finalAnswer.includes(letter) &&
            futureGuessedLetters.has(letter) &&
            !cumulatedCorrectGuessesInWord.has(letter)
          ) {
            cumulatedPartialGuessesInWord.add(letter);
            return true;
          } else {
            return false;
          }
        }

        if (result[index] === " ") {
          return (
            finalAnswer[index] !== letter &&
            (!finalAnswer.includes(letter) ||
              cumulatedPartialGuessesInWord.has(letter) ||
              correctLetters.includes(letter))
          );
        }

        // shouldn't get here
        return false;
      });
    }).map((arr) => arr.join(""));
  });
};
