import React from "react";
import cn from "classnames";
import { inputClassNames, outlineClassNames } from "./commonClassNames";

interface Props extends React.ComponentPropsWithoutRef<"select"> {}

const Select = ({ className, ...other }: Props) => {
  return (
    <select
      {...other}
      className={cn(
        className,
        outlineClassNames,
        inputClassNames,
        "bg-transparent"
      )}
    />
  );
};

export default Select;
