import React, { useContext } from "react";
import TiledResult from "./TiledResult";
import Button from "./Button";
import ResultControls from "./ResultControls";
import ResponsiveContext from "./ResponsiveContext";

interface Props {
  guesses: string[][] | null;
  hideControlsOnMobile: boolean;
  resultAnswers: ResultAnswer[];
  onClearGuess(index: number): void;
  onClickChangeFinalAnswer(): void;
  onClickLetter(index: number, letterIndex: number): void;
  onLockGuess(guess: string, index: number): void;
}

const ResultsAndAnswers = ({
  guesses,
  hideControlsOnMobile,
  resultAnswers,
  onClearGuess,
  onClickLetter,
  onClickChangeFinalAnswer,
  onLockGuess,
}: Props) => {
  const { isDesktop } = useContext(ResponsiveContext);

  return (
    <div className="space-y-1">
      {resultAnswers.map(({ result, answer }, index) => {
        const isLocked = Boolean(answer);
        const isLast = index === resultAnswers.length - 1;

        return (
          <div
            key={index}
            className="flex items-center justify-center gap-2 sm:justify-start"
          >
            <TiledResult
              answer={answer}
              isLast={isLast}
              result={result}
              onClickTile={(letterIndex) => {
                onClickLetter(index, letterIndex);
              }}
            />
            {(isDesktop || !hideControlsOnMobile) && (
              <div className="grow">
                <ResultControls
                  guesses={guesses?.[index] ?? null}
                  isLast={isLast}
                  isLocked={isLocked}
                  onClickChangeFinalAnswer={onClickChangeFinalAnswer}
                  onClearGuess={() => {
                    onClearGuess(index);
                  }}
                  onLockGuess={(guess) => {
                    onLockGuess(guess, index);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ResultsAndAnswers;
