import React from "react";
import cn from "classnames";
import {inputClassNames, inputWellClassNames, outlineClassNames} from "./commonClassNames";

interface Props extends React.ComponentPropsWithoutRef<"input"> {}

const Input = ({ className, ...rest }: Props) => {
  return (
    <input
      {...rest}
      className={cn(className, inputClassNames, inputWellClassNames, outlineClassNames)}
    />
  );
};

export default Input;
