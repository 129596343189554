import React, { useContext, useState } from "react";
import last from "lodash/last";
import isEqual from "lodash/isEqual";
import { solve } from "../solver";
import { parseShare, humanizeList } from "../utils";
import Button from "./Button";
import Field from "./Field";
import Input from "./Input";
import Label from "./Label";
import ResponsiveContext from "./ResponsiveContext";
import ResultsAndAnswers from "./ResultsAndAnswers";
import TextArea from "./TextArea";

const INITIAL_ANSWER_RESULTS = [
  { answer: null, result: "     " },
  { answer: "", result: "GGGGG" },
] as ResultAnswer[];

const getSavedAnswers = () => {
  const savedAnswersRaw = localStorage?.getItem("wordle-answers");
  return savedAnswersRaw ? JSON.parse(savedAnswersRaw) : {};
};

const getSavedAnswer = (id: string) => {
  return getSavedAnswers()[id];
};

const saveAnswer = ({ id, answer }: { id: string; answer: string }) => {
  const prevSavedAnswers = getSavedAnswers();
  localStorage?.setItem(
    "wordle-answers",
    JSON.stringify({ ...prevSavedAnswers, [id]: answer })
  );
};

function App() {
  const [id, setId] = useState(null as string | null);
  const [resultAnswers, setAnswerResults] = useState(INITIAL_ANSWER_RESULTS);
  const [showPaste, setShowPaste] = useState(false);
  const [isChangingFinal, setIsChangingFinal] = useState(false);
  const [pasteText, setPasteText] = useState("");
  // Use for mobile only
  const [hasPasted, setHasPasted] = useState(false);
  const { isMobile, isDesktop } = useContext(ResponsiveContext);
  const finalAnswer = last(resultAnswers)!.answer;
  const hasFullAnswer = finalAnswer?.length === 5;
  const guesses = hasFullAnswer
    ? solve(
        resultAnswers.map(({ result, answer }) => [
          result,
          typeof answer === "string" ? answer.toLowerCase() : answer,
        ])
      )
    : null;
  const lockedGuesses = resultAnswers
    .slice(0, resultAnswers.length - 1)
    .filter((r) => Boolean(r.answer))
    .map((r) => r.answer) as string[];

  const addResult = () => {
    if (resultAnswers.length === 6) {
      return;
    }
    setAnswerResults([{ result: "     ", answer: null }, ...resultAnswers]);
  };
  const removeResult = () => {
    if (resultAnswers.length === 2) {
      return;
    }
    setAnswerResults(resultAnswers.slice(1));
  };
  const reset = () => {
    setAnswerResults(INITIAL_ANSWER_RESULTS);
    setPasteText("");
    setHasPasted(false);
  };
  const changeFinalAnswer = (answer: string) => {
    if (answer.length > 5) {
      return;
    }
    setAnswerResults([
      ...resultAnswers.slice(0, resultAnswers.length - 1),
      { result: "GGGGG", answer },
    ]);
    if (id && answer.length === 5) {
      saveAnswer({ id, answer });
    }
  };
  const clickLetter = (resultIndex: number, letterIndex: number) => {
    if (resultIndex === resultAnswers.length - 1) {
      return;
    }

    setAnswerResults(
      resultAnswers.map((resultAnswer, index) => {
        if (index !== resultIndex) {
          return resultAnswer;
        }
        const result = resultAnswer.result
          .split("")
          .map((letter, lIndex) => {
            if (lIndex !== letterIndex) {
              return letter;
            } else if (letter === " ") {
              return "Y";
            } else if (letter === "Y") {
              return "G";
            } else {
              return " ";
            }
          })
          .join("");
        return { ...resultAnswer, result };
      })
    );
  };
  const lockGuess = (guess: string, resultIndex: number) => {
    setAnswerResults(
      resultAnswers.map((resultAnswer, index) =>
        index === resultIndex
          ? { ...resultAnswer, answer: guess }
          : resultAnswer
      )
    );
  };
  const clearGuess = (index: number) => {
    setAnswerResults(
      resultAnswers.map((resultAnswer, i) =>
        i === index ? { ...resultAnswer, answer: null } : resultAnswer
      )
    );
  };
  const clearAllGuesses = () => {
    setAnswerResults(
      resultAnswers.map((resultAnswer, i) =>
        i === resultAnswers.length - 1
          ? resultAnswer
          : {
              ...resultAnswer,
              answer: null,
            }
      )
    );
  };
  const handlePasteResults = ({
    results,
    id,
  }: {
    results: string[];
    id: string | null;
  }) => {
    if (!results.length) {
      return;
    }
    const savedAnswer = id && getSavedAnswer(id);
    const newResults = results.map((result, index) => ({
      result,
      answer: index === results.length - 1 ? savedAnswer ?? "" : null,
    }));
    setId(id);
    setAnswerResults(newResults);
    setHasPasted(true);
  };
  const share = () => {
    navigator.share({
      text: `Did you guess ${humanizeList(lockedGuesses)}?`,
    });
  };

  const showAnswerInput =
    isDesktop || (hasPasted && !hasFullAnswer) || isChangingFinal;

  return (
    <div className="h-screen overflow-auto bg-slate-50 text-slate-700 dark:bg-slate-900 dark:text-slate-200">
      <div className="container mx-auto px-6 transition-all duration-700 ease-out">
        <div className="my-8 space-y-1 text-center sm:text-left">
          <h1 className="font-serif text-4xl tracking-tight sm:text-2xl sm:tracking-normal">
            Reverse Wordle
          </h1>
          <p className="text-sm opacity-60">
            Annoy people by guessing what they guessed.
          </p>
        </div>
        <div className="space-y-4">
          {isDesktop && (
            <div className="flex gap-1">
              <Button
                onClick={() => {
                  setShowPaste(!showPaste);
                }}
              >
                Paste Share Text
              </Button>
              <Button onClick={addResult} disabled={resultAnswers.length === 6}>
                Add Row
              </Button>
              <Button
                disabled={resultAnswers.length === 2}
                onClick={removeResult}
              >
                Remove Row
              </Button>
              <Button
                onClick={reset}
                disabled={isEqual(resultAnswers, INITIAL_ANSWER_RESULTS)}
              >
                Restart
              </Button>
            </div>
          )}
          {(isMobile ? !hasPasted : showPaste) && (
            <Field>
              <Label className="sm:hidden">Paste Shared Wordle Result:</Label>
              <TextArea
                autoFocus
                onChange={(evt) => {
                  evt.preventDefault();
                  setPasteText(evt.target.value);
                  const { results, id } = parseShare(evt.target.value);
                  if (!results) {
                    return;
                  }
                  handlePasteResults({ results, id });
                  setShowPaste(false);
                }}
                value={pasteText}
              />
            </Field>
          )}
          {(isDesktop || hasPasted) && (
            <ResultsAndAnswers
              guesses={guesses}
              hideControlsOnMobile={showAnswerInput}
              resultAnswers={resultAnswers}
              onClearGuess={clearGuess}
              onClickChangeFinalAnswer={() => {
                clearAllGuesses();
                setIsChangingFinal(true);
              }}
              onClickLetter={clickLetter}
              onLockGuess={lockGuess}
            />
          )}
          {showAnswerInput && (
            <div
              // w-64 to match the width of the tiles
              className="mx-auto w-64 sm:mx-0 sm:w-auto"
            >
              <Field>
                <Label id="answer-label" htmlFor="answer-input">
                  Wordle Answer:
                </Label>
                <Input
                  id="answer-input"
                  autoFocus
                  value={finalAnswer ?? ""}
                  onChange={(evt) => {
                    changeFinalAnswer(evt.target.value);
                  }}
                  onBlur={() => {
                    if (hasFullAnswer && isChangingFinal) {
                      setIsChangingFinal(false);
                    }
                  }}
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "Enter" &&
                      finalAnswer?.length === 5 &&
                      isChangingFinal
                    ) {
                      setIsChangingFinal(false);
                    }
                  }}
                  type="text"
                />
              </Field>
            </div>
          )}
          {isMobile && hasPasted && hasFullAnswer && !isChangingFinal && (
            <div className="flex justify-center gap-2">
              <Button onClick={reset} variant="primary">
                Restart
              </Button>
              {Boolean(navigator.share) && (
                <Button
                  disabled={lockedGuesses.length === 0}
                  variant="primary"
                  onClick={share}
                >
                  Share
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
