import React from "react";
import cn from "classnames";

interface Props extends React.ComponentPropsWithoutRef<"label"> {}

const Label = ({ className, ...other }: Props) => {
  return (
    <label
      {...other}
      className={cn("block text-sm font-semibold dark:font-normal", className)}
    />
  );
};

export default Label;
