import React from "react";
import cn from "classnames";
import {
  inputClassNames,
  inputWellClassNames,
  outlineClassNames,
} from "./commonClassNames";

interface Props extends React.ComponentPropsWithoutRef<"textarea"> {}

const TextArea = ({ className, ...other }: Props) => {
  return (
    <textarea
      {...other}
      className={cn(
        className,
        inputClassNames,
        inputWellClassNames,
        outlineClassNames,
        "h-64 sm:w-64"
      )}
    />
  );
};

export default TextArea;
