import React from "react";
import Tile from "./Tile";

interface Props {
  answer: string | null;
  isLast: boolean;
  result: string;
  onClickTile(index: number): void;
}

const TiledResult = ({
  answer,
  isLast,
  result,
  onClickTile,
}: Props): React.ReactElement => {
  return (
    <div className="flex gap-1">
      {result.split("").map((letter, index) => (
        <Tile
          key={index}
          letter={answer ? answer!.toUpperCase().charAt(index) : null}
          status={letter}
          disabled={isLast}
          onClick={() => {
            onClickTile(index);
          }}
        />
      ))}
    </div>
  );
};

export default TiledResult;
