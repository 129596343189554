import React from "react";
import cn from "classnames";
import { outlineClassNames } from "./commonClassNames";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  size?: "sm" | "md";
  variant?: "primary" | "secondary";
}

const Button = ({
  size = "md",
  className,
  disabled,
  variant = "primary",
  ...rest
}: Props): JSX.Element => {
  return (
    <button
      className={cn(
        className,
        outlineClassNames,
        "block rounded border",
        size === "sm"
          ? "py-0.5 px-2 text-sm sm:py-0.5 sm:px-1 sm:text-xs"
          : "text-md py-2 px-4 sm:py-1 sm:px-2 sm:text-sm",
        variant === "primary"
          ? "border-1 border-slate-600 bg-slate-500 text-slate-50 drop-shadow transition-colors active:bg-slate-600 dark:border-transparent dark:bg-white dark:text-slate-600 dark:active:bg-slate-100"
          : "border-slate-400 bg-white drop-shadow-sm dark:border-transparent dark:bg-slate-800 dark:text-slate-400",
        {
          "opacity-30": disabled,
        }
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Button;
