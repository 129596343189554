import React from "react";
import cn from "classnames";
import { outlineClassNames } from "./commonClassNames";

interface Props {
  disabled?: boolean;
  letter: string | null;
  status: string;
  onClick(): void;
}

const Tile = ({ disabled, letter, status, onClick }: Props) => {
  return (
    <button
      className={cn(
        outlineClassNames,
        "pointer-events-none flex h-12 w-12 items-center justify-center rounded-sm border transition-colors dark:border-transparent",
        disabled ? "sm:pointer-events-none" : "sm:pointer-events-auto",
        {
          "border-gray-300 bg-gray-200 text-gray-800 hover:border-gray-400 hover:bg-gray-300":
            status === " ",
          "border-green-400 bg-green-300 text-green-800 hover:border-green-500 hover:bg-green-400":
            status === "G",
          "border-yellow-400 bg-yellow-300 text-yellow-900 hover:border-yellow-500 hover:bg-yellow-400":
            status === "Y",
        }
      )}
      onClick={onClick}
      tabIndex={disabled ? -1 : undefined}
    >
      {letter}
    </button>
  );
};

export default Tile;
