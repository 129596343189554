const CORRECT = ["dfe7", "dfe9"];
const PARTIAL = ["dfe6", "dfe8"];
const WRONG = ["2b1b", "2b1c"];

export const parseShare = (input: string) => {
  const lines = input.split("\n");
  const results = [] as string[];
  let id = null;

  lines.forEach((line) => {
    const match = line.match(/Wordle ([0-9]*)/);
    if (match) {
      id = match[1];
    }
    let result = "";
    for (let i = 0; i < line.length; i++) {
      const character = line.charAt(i);
      const converted = character.codePointAt(0)?.toString(16);

      // can't use g because it's in "large_white_square"
      if (character === "n" || CORRECT.includes(converted!)) {
        result += "G";
      }
      if (
        character === "y" ||
        character === "Y" ||
        PARTIAL.includes(converted!)
      ) {
        result += "Y";
      }
      if (
        WRONG.includes(converted!) ||
        character === "k" ||
        character === "t"
      ) {
        result += " ";
      }
      if (result.length === 5) {
        results.push(result);
        break;
      }
    }
  });

  return { results, id };
};

export const humanizeList = (words: string[]) => {
  if (words.length === 0) {
    return "";
  }
  if (words.length === 1) {
    return words[0];
  }
  const lastTwo = words.slice(-2).join(" and ");
  return words
    .slice(0, words.length - 2)
    .concat(lastTwo)
    .join(", ");
};
