import React, { useContext } from "react";
import cn from "classnames";
import { inputClassNames, outlineClassNames } from "./commonClassNames";
import Button from "./Button";
import GuessSelector from "./GuessSelector";
import ResponsiveContext from "./ResponsiveContext";
import Select from "./Select";

interface Props {
  guesses: string[] | null;
  isLast: boolean;
  isLocked: boolean;
  onClearGuess(): void;
  onClickChangeFinalAnswer(): void;
  onLockGuess(guess: string): void;
}

const ResultControls = ({
  guesses,
  isLast,
  isLocked,
  onClearGuess,
  onClickChangeFinalAnswer,
  onLockGuess,
}: Props) => {
  const { isMobile } = useContext(ResponsiveContext);

  if (isLast && isMobile) {
    return (
      <div className="flex justify-center">
        <Button
          size="sm"
          variant="secondary"
          onClick={onClickChangeFinalAnswer}
        >
          Change
        </Button>
      </div>
    );
  }

  if (isLast) {
    return null;
  }

  if (isLocked) {
    return (
      <div className="flex justify-center sm:justify-start">
        <Button
          className="capitalize sm:w-auto sm:lowercase"
          variant="secondary"
          size="sm"
          onClick={onClearGuess}
        >
          clear
        </Button>
      </div>
    );
  }

  if (!guesses) {
    return null;
  }

  if (isMobile) {
    return (
      <Select
        onChange={(evt) => {
          onLockGuess(evt.target.value);
        }}
        value=""
      >
        <option>{guesses.length} Words</option>
        {guesses.map((guess) => (
          <option key={guess} value={guess}>
            {guess.toUpperCase()}
          </option>
        ))}
      </Select>
    );
  }

  return <GuessSelector guesses={guesses} onLockGuess={onLockGuess} />;
};

export default ResultControls;
