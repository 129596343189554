import React, { useState } from "react";
import Button from "./Button";

const GUESS_LIMIT = 20;

interface Props {
  guesses: string[];
  onLockGuess(guess: string): void;
}

const GuessSelector = ({ guesses, onLockGuess }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const initialGuesses = guesses.slice(0, GUESS_LIMIT);
  const moreGuesses = guesses
    ? guesses.length > GUESS_LIMIT
      ? guesses.slice(GUESS_LIMIT)
      : null
    : null;

  return (
    <div className="flex select-none flex-wrap items-center gap-1">
      {initialGuesses.map((guess) => (
        <Button
          key={guess}
          size="sm"
          variant="secondary"
          onClick={() => {
            onLockGuess(guess);
          }}
        >
          {guess}
        </Button>
      ))}
      {showMore &&
        moreGuesses &&
        moreGuesses.map((guess) => (
          <Button
            key={guess}
            size="sm"
            variant="secondary"
            onClick={() => {
              onLockGuess(guess);
            }}
          >
            {guess}
          </Button>
        ))}
      {moreGuesses && !showMore && (
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            setShowMore(true);
          }}
        >
          show {moreGuesses.length} more
        </Button>
      )}
      {moreGuesses && showMore && (
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            setShowMore(false);
          }}
        >
          hide guesses
        </Button>
      )}
    </div>
  );
};

export default GuessSelector;
